<template>
  <div class="col-md-6 col-12 ma-0" v-if="isDataLoaded">
    <div class="pa-4 pa-sm-6 card elevation-2 poppins">
      <h3 class="primary--text font-size-h6 font-size-h3-sm">
        {{ serverData.title ? serverData["title"] : "" }}
      </h3>
      <div class="d-flex align-center justify-space-between">
        <div>
          <h6 class="font-size-lg font-size-h6-sm font-weight-medium">
            Total orders:
            <span class="primary--text">{{ serverData.total }}</span>
          </h6>
          <h6 class="font-size-lg font-size-h6-sm font-weight-medium">
            Client number:
            <span class="primary--text">{{ serverData.total_customers }}</span>
          </h6>
        </div>
        <button
          class="btn iq-btn px-3 px-sm-4 poppins-ls"
          @click="exportChart(serverData.print_url)"
        >
          Export
        </button>
      </div>
    </div>
    <v-card outlined>
      <perfect-scrollbar class="scroll" style="max-height: 75vh">
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(item, i) in serverData.values" :key="i">
            <v-expansion-panel-header class="pa-0 px-sm-6 py-sm-4">
              <div class="row align-center bg-white">
                <client-column
                  class="w-200px w-sm-auto"
                  :value="item.customer"
                  >{{ item.customer }}</client-column
                >
                <v-spacer></v-spacer>
                <h6
                  class="pt-2 pr-4 primary--text font-size-base font-size-h6-sm font-weight-medium"
                >
                  {{ item.total }}
                </h6>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <h5>COUNTRIES</h5>
                      </th>
                      <th class="text-left">
                        <h5>SALES</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in item.countries" :key="item.name">
                      <td>
                        <h6>{{ item.country_name }}</h6>
                      </td>
                      <td>
                        <h6>{{ item.amount }}</h6>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </perfect-scrollbar>
    </v-card>
  </div>
  <v-skeleton-loader
    class="col-md-6 col-12 ma-0"
    v-else
    type="card"
  ></v-skeleton-loader>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ClientColumn from "@/own/components/datatable/ClientColumn.vue";
export default {
  name: "AnalyticsOrders",
  props: ["item", "filterData", "exportChart"],
  components: {
    ClientColumn,
  },
  data: () => ({
    serverData: null,
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded() {
      return !!this.serverData;
    },
  },
  watch: {
    filterData() {
      this.serverData = null;
      this.loadDataFromServer();
    },
  },
};
</script>
