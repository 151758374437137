<template v-if="userData">
  <div class="p-4 bg-white rounded-lg">
    <analytics-layout
      :userData="userData"
      :updateDashboardData="updateDashboardData"
      :filters="filters"
    ></analytics-layout>
  </div>
</template>

<script>
import { UPDATE_DASHBOARD_DATA } from "@/core/services/store/analytics.module";
import AnalyticsLayout from "@/own/components/dashboard/AnalyticsLayout.vue";
export default {
  components: { AnalyticsLayout },
  name: "Analitics",
  data: () => ({
    data: null,
  }),
  methods: {
    updateDashboardData(
      serverData = { date_range_type: "this_month", date_range: null }
    ) {
      if (serverData.date === "custom") {
        this.$store.dispatch(UPDATE_DASHBOARD_DATA, serverData).then(() => {
          this.data = this.$store.getters.getANALYTICSTableData;
        });
      } else {
        this.$store.dispatch(UPDATE_DASHBOARD_DATA, serverData).then(() => {
          this.data = this.$store.getters.getANALYTICSTableData;
        });
      }
      this.data = this.$store.getters.getANALYTICSTableData;
    },
  },
  computed: {
    filters: function () {
      return this.$store.getters.getANALYTICSFilter;
    },
    userData: function () {
      return this.data;
    },
  },
  beforeMount() {
    this.updateDashboardData();
  },
};
</script>
