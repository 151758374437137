<template>
  <div class="col-md-6 col-12 ma-0" v-if="isDataLoaded">
    <div class="pa-6 card elevation-2 poppins">
      <h3 class="primary--text font-size-h6 font-size-h3-sm">
        {{ serverData.title ? serverData["title"] : "" }}
      </h3>
      <div class="d-flex align-center justify-space-between">
        <div>
          <h6>
            Total inventory:
            <span class="primary--text">{{ serverData.total }}</span>
          </h6>
          <h6>
            Client number:
            <span class="primary--text">{{ serverData.total_customers }}</span>
          </h6>
        </div>
        <button
          class="btn iq-btn px-4 poppins-ls"
          @click="exportChart(serverData.print_url)"
        >
          Export
        </button>
      </div>
    </div>

    <div class="elevation-2">
      <perfect-scrollbar class="scroll" style="max-height: 75vh">
        <v-card
          v-for="(item, i) in serverData.values"
          :key="i"
          class="ma-0 px-1"
        >
          <v-card-text class="ma-0">
            <div class="row align-center bg-white">
              <client-column class="w-200px w-sm-auto" :value="item.customer">{{
                item.customer
              }}</client-column>
              <v-spacer></v-spacer>
              <h6 class="pt-2 pr-4 primary--text">{{ item.total }}</h6>
            </div>
          </v-card-text>
        </v-card>
      </perfect-scrollbar>
    </div>
  </div>
  <v-skeleton-loader
    class="col-md-6 col-12 ma-0"
    v-else
    type="card"
  ></v-skeleton-loader>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ClientColumn from "@/own/components/datatable/ClientColumn.vue";
export default {
  name: "AnalyticsInventory",
  props: ["item", "filterData", "exportChart"],
  components: {
    ClientColumn,
  },
  data: () => ({
    serverData: null,
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded() {
      return !!this.serverData;
    },
  },
  watch: {
    filterData() {
      this.serverData = null;
      this.loadDataFromServer();
    },
  },
};
</script>
